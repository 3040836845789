import { sva } from "@@panda/css";
import { token } from "@@panda/tokens";
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";

export type Variant = "info" | "info2" | "warning" | "emphatic" | "error";

export const statusToIconMap: { [k in Variant]: React.ReactNode } = {
  info: (
    <InformationCircleIcon color={token("colors.grey.iron")} width="24px" />
  ),
  info2: (
    <InformationCircleIcon color={token("colors.grey.iron")} width="24px" />
  ),
  emphatic: (
    <InformationCircleIcon color={token("colors.grey.bunker")} width="24px" />
  ),
  error: (
    <InformationCircleIcon color={token("colors.red.jiren")} width="24px" />
  ),
  warning: <ExclamationCircleIcon color="#eab308" width="24px" />,
};

export const alertStyles = sva({
  slots: ["root", "title", "desc"],
  base: {
    root: {
      rounded: "0.875rem",
      position: "relative",
      px: "s",
      py: "0.6rem!",
    },
    title: {
      lineHeight: "unset",
      fontSize: "0.875rem",
    },
    desc: {
      lineHeight: "unset",
      mt: "0",
    },
  },
  variants: {
    variant: {
      info: {
        root: {
          backgroundColor: "grey.minecraft",
          backgroundImage: "linear-gradient(90deg, #202020 0%, #303030 100%)",
        },
        title: {
          color: "grey.iron",
        },
        desc: {
          color: "grey.iron",
        },
      },
      info2: {
        root: {
          backgroundColor: "grey.minecraft",
          backgroundImage: "linear-gradient(90deg, #3e3e3e 0%, #303030 100%)",
        },
        title: {
          color: "grey.iron",
        },
        desc: {
          color: "grey.iron",
        },
      },
      emphatic: {
        root: {
          background: "brand.yamcha",
        },
        title: {
          color: "grey.bunker!",
        },
        desc: {
          color: "grey.bunker!",
        },
      },
      warning: {
        root: {
          background: "linear-gradient(90deg, #754e00 0%, #372100 100%)",
        },
        title: {
          color: "amber.900",
        },
        desc: {
          color: "#B45309",
        },
      },
      error: {
        root: {
          background: "red.mist",
        },
        title: {
          color: "red.jiren",
        },
        desc: {
          color: "red.jiren",
        },
      },
    },
  },
});
